import { useEffect, useState } from 'react';

export function useCountDown(countDownTime) {
  const [countDown, setCountDown] = useState(countDownTime);
  const [random, setRandom] = useState(1);

  const restart = () => {
    setCountDown(countDownTime);
    setRandom(rand => rand + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(cnt => {
        if(cnt <= 0) {
          clearInterval(interval);
          return 0;
        }
        return cnt - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [random]);

  return [countDown, restart];
}
