import {
  addAddress,
  getAddresses,
  updateProfile,
  uploadDp,
  validateUserName,
  sendOtp,
  validateEmail,
  updateAddress,
  deleteAddress,
  validateEmailGetToken
} from 'ui/api/user';
import { Loader } from 'ui/services';
import {
  useIsVisitor
} from 'contexts/profile';
import { SnackBar } from 'ui/services';

export function useAddress() {
  const isVisitor = useIsVisitor();
  const add = async payload => {
    try {
      Loader.show();
      const res = await addAddress(payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const get = async (requireCoords = false) => {
    try {
      if (isVisitor) {
        return [];
      }
      Loader.show();
      let res = await getAddresses();
      if (requireCoords) {
        res = res.filter(x => x.coords);
      } else {
        res = res.filter(x => !x.coords);
      }
      Loader.hide();
      return res.reverse();
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const update = async payload => {
    try {
      Loader.show();
      const res = await updateAddress(payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const remove = async payload => {
    try {
      Loader.show();
      const res = await deleteAddress(payload);
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  return [add, get, update, remove];
}

export function useUser() {
  const verifyUser = async payload => {
    try {
      return await validateUserName(payload);
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  const uploadPic = async (payload) => {
    try {
      return await uploadDp(payload);
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  const updateUser = async (payload) => {
    try {
      return await updateProfile(payload);
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  const sendCode = async (payload) => {
    try {
      return await sendOtp(payload);
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  const validateMail = async (payload) => {
    try {
      let res = await validateEmail(payload);
      return res;
    } catch (e) {
      SnackBar.showError(e?.response?.data?.message);
    }
  };

  const validateMailGetToken = async (payload) => {
    try {
      return await validateEmailGetToken(payload);
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  return [verifyUser, uploadPic, updateUser, sendCode, validateMail, validateMailGetToken];
}
