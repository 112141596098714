import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useIsCustomDomain } from 'contexts/profile';

export function useFirebase() {
  const isCustomDomain = useIsCustomDomain();
  useEffect(() => {
    if(isCustomDomain) {
      return;
    }
    const firebaseConfig = {
      apiKey: 'AIzaSyCl3t5cIetw8ypSXfaH6DZ9ds5n1-_8Bk4',
      authDomain: 'windo-beta.firebaseapp.com',
      projectId: 'windo-beta',
      storageBucket: 'windo-beta.appspot.com',
      messagingSenderId: '40104794528',
      appId: '1:40104794528:web:f70156e6011cab5104ecc5',
      measurementId: 'G-T82PVXB1QF'
    };
    if (firebase.apps.length > 0) {
      firebase.app();
    } else {
      firebase.initializeApp(firebaseConfig);
    }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
    });
  }, []);

}
