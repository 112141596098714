import CONFIG from 'ui/config';

export const getWindoUrl = () => {
  switch (CONFIG.env) {
    case 'development':
      return 'http://localhost:4000';
    case 'staging':
      return 'https://staging.mywindo.shop';
    default:
      return 'https://mywindo.shop';
  }
};
