import { GoogleLogin } from 'react-google-login';
import React, { useEffect, useRef, useState } from 'react';
import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Loader, SnackBar } from 'ui/services';
import CONFIG from 'ui/config';
import { FIELD_PROPS, LOGIN_BUTTONS, testEmail } from 'components/login/utils';
import {isValidPhoneNum} from 'components/utils/validation';
import { useUser } from 'hooks/user';
import Api from 'ui/api';
import { useRouter } from 'next/router';
import { Button, Clickable, ReactInput } from 'phoenix-components';
import styles from './Login.module.css';
import { useIsCustomDomain, useIsDesktop, useIsGuest, useRefreshProfile } from 'contexts/profile';
import { getWindoUrl } from 'ui/lib/utils';
import ShopUtils from 'ui/lib/shopUtils';
import { useQueryParams } from 'hooks/location';
import { ShopLogo } from 'components/icons/login';
import { Loading } from 'components/shared/Loading';
import { OtpComponent } from 'components/login/OtpComponent';
import { DrawerContainer } from 'components/login/DrawerContainer';
import Phone from 'components/common/Phone';
import { useFirebase } from 'hooks/firebase';
import firebase from 'firebase/app';
import { errorMessagesForPhone } from 'components/login/utils';

function Login({
  shop,
  onClose,
  getToken,
  type,
}) {

  const router = useRouter();
  const [, , , sendCode, validateMail] = useUser();
  const [err, setErr] = useState(null);
  const [identifier, setIdentifier] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const isCustomDomain = useIsCustomDomain();
  const refreshProfile = useRefreshProfile();
  const isGuest = useIsGuest();
  const params = useQueryParams();
  const [googleOpened, setGoogleOpened] = useState(false);
  const [verificationType, setVerificationType] = useState(type);
  const [value, setValue] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [firebaseResult, setFirebaseResult] = useState(null);
  const [googleAllowed, setGoogleAllowed] = useState(true);

  useEffect(()=>{
    if(window.isWebView) {
      setGoogleAllowed(false);
    }
  },[])

  useFirebase();

  const isDesktop = useIsDesktop();

  const googleRef = useRef();

  const getLoginUrl = type => {
    let url = `${window.location.protocol}//${window.location.host}/loginWithToken?token={TOKEN}`;
    url = `${url}&redirectPath=${window.location.pathname}${window.location.search}`;
    const windoUrl = getWindoUrl();
    return `${windoUrl}/login?url=${encodeURIComponent(url)}&shopId=${shop._id}&type=${type}`;
  };

  const handleGoogle = async () => {
    if (isCustomDomain) {
      Loader.show();
      window.location.href = getLoginUrl('google');
      return;
    }
    if (googleRef?.current?.click) {
      googleRef.current.click();
    }
  };

  const handleOnClose = async () => {
    refreshProfile();
    if (params.has('openMenu')) {
      params.delete('openMenu');
      await router.replace({
        query: params.toString(),
      }, undefined, { shallow: true });
    }
    onClose();
  };

  const handleGuest = async () => {
    try {
      Loader.show();
      await Api.loginAsGuest();
      await handleOnClose();
    } catch (e) {
      SnackBar.showError(e);
      return true;
    } finally {
      Loader.hide();
    }
  };

  const handlePhone = () => {
    if (isCustomDomain) {
      Loader.show();
      window.location.href = getLoginUrl('phone');
      return;
    }
    return setVerificationType('phone');
  };

  const onClick = (id) => () => {
    switch (id) {
      case 'google':
        return handleGoogle();
      case 'guest':
        return handleGuest();
      case 'email':
        return setVerificationType('email');
      case 'phone':
        return handlePhone();
      case 'whatsapp':
        return setVerificationType('whatsapp');
    }
  };

  const loginWithToken = async (token, platform) => {
    try {
      Loader.show();
      if (getToken) {
        const data = await Api.loginGetToken(token, platform);
        onClose(data.token);
        return;
      }
      await Api.login(token, platform);
      await handleOnClose();
    } catch (e) {
      SnackBar.showError(e);
    } finally {
      Loader.hide();
    }
  };

  const responseGoogle = async (response) => {
    await loginWithToken(response.tokenId, 'google');
  };

  const responseFailGoogle = (response) => {
    console.error('login failed due to', response);
    SnackBar.showError('unable to login try again');
  };

  const onNext = async () => {
    switch (verificationType) {
      case 'email':
        return onEmailContinue();
      case 'whatsapp':
        return onWhatsappContinue();
      case 'phone':
        return onPhoneContinue();
    }
  };

  const onEmailContinue = async () => {
    if(!value){
      setErr('Enter a valid email');
      return;
    }
    if (!testEmail(value)) {
      setErr('Enter a valid email');
      return;
    }
    try {
      Loader.show();
      const { identifier: id } = await sendCode({ email: value, shopPlan:shop?.plan });
      SnackBar.show(`OTP has been sent to ${value}`);
      setIdentifier(id);
      setShowOtp(true);
    } catch (e) {
      SnackBar.show('Network connectivity issue. Please check your internet and try again.', 'error');
    } finally {
      Loader.hide();
    }
  };

  const onWhatsappContinue = async () => {
    const phone = `${countryCode}${value}`;
    if (!countryCode) {
      setErr('Select a country code');
      return;
    }
    if (!isValidPhoneNum(value, countryCode)) {
      setErr('Enter a valid phone number');
      return;
    }
    try {
      Loader.show();
      const { identifier: id } = await Api.loginPhone({
        phone,
        type: 'whatsapp'
      });
      SnackBar.show(`OTP has been sent to ${value}`);
      setIdentifier(id);
      setShowOtp(true);
    } catch (e) {
      SnackBar.show('Network connectivity issue. Please check your internet and try again.', 'error');
    } finally {
      Loader.hide();
    }
  };

  const onPhoneContinue = async () => {
    try {
      Loader.show();
      const phone = `${countryCode}${value}`;
      if (!countryCode) {
        setErr('Select a country code');
        return;
      }
      if (!isValidPhoneNum(value, countryCode)) {
        setErr('Enter a valid phone number');
        return;
      }
      const result = await firebase.auth()
        .signInWithPhoneNumber(phone, window.recaptchaVerifier);
      setFirebaseResult(result);
      SnackBar.show(`OTP has been sent to ${phone}`);
      setShowOtp(true);
    } catch (e) {
      SnackBar.showError(e);
    } finally {
      Loader.hide();
    }
  };

  const verifyEmailOtp = async (otp) => {
    try {
      Loader.show();
      let result = await validateMail({
        email: value,
        code: otp.toUpperCase(),
        identifier
      });
      if (result) {
        await handleOnClose();
        return;
      }
    } catch (e) {
      const error = e?.response?.data?.message;
      if (error) {
        SnackBar.show(error, 'error');
        return;
      }
      SnackBar.show('Network connectivity issue. Please check your internet and try again.', 'error');
    } finally {
      Loader.hide();
    }
  };

  const verifyWhatsappOtp = async (otp) => {
    const phone = `${countryCode}${value}`;
    try {
      Loader.show();
      await Api.verifyPhone({
        phone,
        code: otp.toUpperCase(),
        identifier,
        type: 'whatsapp'
      });
      await handleOnClose();
    } catch (e) {
      const error = e?.response?.data?.message;
      if (error) {
        SnackBar.show(error, 'error');
        return;
      }
      SnackBar.show('Network connectivity issue. Please check your internet and try again.', 'error');
    } finally {
      Loader.hide();
    }
  };

  const verifyPhoneOtp = async (otp) => {
    const phone = `${countryCode}${value}`;
    try {
      Loader.show();
      const res = await firebaseResult.confirm(otp);
      const code = await res.user.getIdToken();
      const result = await Api.verifyPhone({
        phone,
        code,
        type: 'phone',
        returnToken: !!getToken,
      });
      if (getToken) {
        onClose(result.token);
        return;
      }
      await handleOnClose();
    } catch (e) {
      // patch, will change err response
      const errorMessage = errorMessagesForPhone[e?.code] || 'An error has occurred. Please try again later.';
      SnackBar.showError(errorMessage);
    } 
    
    finally {
      Loader.hide();
    }
  };

  const onOtpSubmit = (otp) => {
    switch (verificationType) {
      case 'email':
        return verifyEmailOtp(otp);
      case 'whatsapp':
        return verifyWhatsappOtp(otp);
      case 'phone':
        return verifyPhoneOtp(otp);
    }
  };

  const onOtpResend = () => onNext();

  const onOtpClose = () => {
    setShowOtp(false);
    setIdentifier('');
  };

  const onSetPhoneValue = ({
    phone,
    countryCode
  }) => {
    setErr(null);
    setValue(phone);
    setCountryCode(countryCode);
  };

  const buttons = LOGIN_BUTTONS.filter(x => ShopUtils.isCheckoutAllowed(shop, x.identifier))
    .filter(x => (isGuest && x.identifier !== 'guest') || !isGuest);

  const logo = ShopUtils.getLogo(shop);

  const onVerificationClose = () => {
    setErr(null);
    setValue('');
    setCountryCode('');
    setVerificationType(null);
  };

  return (
    <>
      <div id={'sign-in-button'}/>
      {type === 'google' && (
        <Loading/>
      )}
      <Drawer
        anchor={'bottom'}
        open={true}
        onClose={() => {
          onClose(false);
        }}
        PaperProps={{
          classes: {
            root: cx(styles.paper, {
              [styles.hidden]: type === 'google'
            })
          }
        }}
      >
        <div
          className={cx(styles.container,{
            [styles.verificationInput]: Boolean(verificationType)
          })}>
          {!isCustomDomain && ShopUtils.isCheckoutAllowed(shop, 'google') && googleAllowed && (
            <GoogleLogin
              clientId={CONFIG.GAUTH.CLIENT_ID}
              render={renderProps => {
                if (type === 'google' && !googleOpened) {
                  setTimeout(() => {
                    renderProps.onClick();
                    setGoogleOpened(true);
                  }, 2000);
                }
                return (
                  <button
                    ref={googleRef}
                    onClick={renderProps.onClick}
                    className={styles.hidden}
                  />
                );
              }}
              onSuccess={responseGoogle}
              onFailure={responseFailGoogle}
              fetchBasicProfile={true}
              isSignedIn={false}
              cookiePolicy="single_host_origin"
            />
          )}
          {isDesktop && (
            <div className={styles.left}>
              {logo ? (
                <img src={logo} alt=""/>
              ) : (
                <ShopLogo/>
              )}
            </div>
          )}
          <div className={styles.right}>
            {verificationType && showOtp && (
              <OtpComponent
                onSubmit={onOtpSubmit}
                digits={6}
                onClose={onOtpClose}
                title={FIELD_PROPS[verificationType]?.otpTitle}
                onResend={onOtpResend}
              />
            )}
            {verificationType && !showOtp && (
              <DrawerContainer
                onClose={onVerificationClose}
                title={FIELD_PROPS[verificationType]?.title}
              >
                <div className={styles.inputContainer}>
                  {verificationType === 'email' && (
                    <ReactInput
                      value={value}
                      setValue={setValue}
                      type={'email'}
                      label={'Enter Email ID'}
                      placeholder={'e.g. xyz@xyz.com'}
                    />
                  )}
                  {['phone', 'whatsapp'].includes(verificationType) && (
                    <Phone
                      value={{
                        phone: value,
                        countryCode: countryCode
                      }} onChange={onSetPhoneValue}/>
                  )}
                  {err && (<div className={styles.err}>
                    {err}
                  </div>)}
                </div>
                <div className={styles.center}>
                  <Button
                    className={styles.submitButton}
                    onClick={onNext}
                    label="Next"
                    size="large"
                    fullWidth
                    rounded={false}
                  />
                </div>
              </DrawerContainer>
            )}
            {!verificationType && (<>
              <div className={styles.heading}>Sign in with</div>
              <div className={styles.buttons}>
                {isGuest && !buttons.length && ( <Clickable
                  onClick={
                    async()=>await handleOnClose()} 
                  key={'guest'} className={styles.button}>
                  <span>    
                    <img src={"/images/login/v2/guest.svg" } alt={"Continue as Guest"}/>
                  </span>
                  <span>Continue as Guest</span>
                </Clickable>)}
                {buttons.map(x => (
                  <Clickable onClick={onClick(x.identifier)} key={x.identifier} className={styles.button}>
                    <span>
                      <img src={x.icon} alt={x.label}/>
                    </span>
                    <span>{x.label}</span>
                  </Clickable>
                ))}
              </div>
            </>)}
          </div>
        </div>
      </Drawer>
    </>
  );
}

Login.propTypes = {
  onClose: PropTypes.func.isRequired,
  getToken: PropTypes.bool,
  shop: PropTypes.object,
  type: PropTypes.string,
};

Login.defaultProps = {
  getToken: false,
  shop: {},
  type: null,
};

export default Login;
