import * as Yup from 'yup';
const schema = Yup.string().email();

export function testEmail(email) {
  return schema.isValidSync(email)
}

export function testPhone(phone) {
  return /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
}

export const FIELD_PROPS = {
  email: {
    title: 'Sign in with Email',
    otpTitle: 'Enter the verification code sent to your email inbox',
  },
  phone: {
    title: 'Sign in with Phone',
    otpTitle: 'Enter the verification code sent to your phone',
  },
  whatsapp: {
    title: 'Sign in with WhatsApp',
    otpTitle: 'Enter the verification code sent to your whatsapp',
  },
};

export const errorMessagesForPhone = {
  'auth/invalid-verification-code': 'Invalid OTP',
  'auth/code-expired': 'OTP has expired. Please re-send the verification code to try again.',
  'auth/too-many-requests': 'Too many attempts. Please try again later.',
  'auth/missing-verification-code': 'Enter a valid OTP'
};

export const LOGIN_BUTTONS = [
  {
    label: 'Email',
    identifier: 'email',
    icon: '/images/login/v2/email.svg',
  },
  {
    label: 'Google',
    identifier: 'google',
    icon: '/images/login/v2/google.svg',
  },
  {
    label: 'WhatsApp',
    identifier: 'whatsapp',
    icon: '/images/login/v2/whatsapp.svg',
  },
  {
    label: 'Phone Number',
    identifier: 'phone',
    icon: '/images/login/v2/phone.svg',
  },
  {
    label: 'Continue as Guest',
    identifier: 'guest',
    icon: '/images/login/v2/guest.svg',
  },
];
