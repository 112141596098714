/* eslint-disable max-len */
export function ShopLogo() {
  return (
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="200" height="200" rx="100" fill="#F4F4F4"/>
      <path
        d="M55.583 63.2111H144.299C144.804 63.2111 145.288 63.4207 145.64 63.7919L146.716 64.9217V51.9202C146.716 50.8587 145.877 50 144.841 50H55.1489C54.1127 50 53.2734 50.8587 53.2734 51.9202V64.7987L54.2459 63.7851C54.6009 63.4184 55.0813 63.2111 55.583 63.2111Z"
        fill="var(--primary)"/>
      <path
        d="M144.721 105.068C140.53 104.501 136.939 101.959 134.875 98.3828C132.521 102.453 128.191 105.187 123.25 105.187C118.306 105.187 113.975 102.453 111.625 98.3828C109.271 102.453 104.943 105.187 99.9999 105.187C95.0567 105.187 90.727 102.453 88.375 98.3828C86.0231 102.453 81.6934 105.187 76.7502 105.187C71.8089 105.187 67.4773 102.453 65.1253 98.3828C63.1032 101.884 59.6129 104.401 55.5282 105.032C54.8664 105.134 54.1892 105.187 53.5004 105.187C52.9177 105.187 52.3446 105.148 51.7812 105.075V154.33C51.7812 155.391 52.6206 156.25 53.6547 156.25H146.594C147.63 156.25 148.469 155.391 148.469 154.33V105.038C147.827 105.139 147.169 105.187 146.499 105.187C145.897 105.187 145.303 105.148 144.721 105.068ZM107.797 135.522C107.797 136.581 106.957 137.442 105.923 137.442H64.9015C63.8653 137.442 63.026 136.581 63.026 135.522V112.284C63.026 111.225 63.8653 110.364 64.9015 110.364H105.921C106.956 110.364 107.795 111.225 107.795 112.284V135.522H107.797ZM136.748 143.774C136.748 144.833 135.909 145.694 134.875 145.694H116.275C115.239 145.694 114.399 144.833 114.399 143.774V112.284C114.399 111.225 115.239 110.364 116.275 110.364H134.875C135.909 110.364 136.748 111.225 136.748 112.284V143.774Z"
        fill="var(--primary)"/>
      <path d="M153.455 77.4916L143.507 67.0503H129.613L136.044 77.4916H153.455Z" fill="var(--primary)"/>
      <path
        d="M125.311 67.2712C125.269 67.2006 125.228 67.1277 125.195 67.0503H111.035L113.179 77.4916H131.605L125.311 67.2712Z"
        fill="var(--primary)"/>
      <path d="M92.792 67.0503L90.6484 77.4916H109.376L107.232 67.0503H92.792Z" fill="var(--primary)"/>
      <path
        d="M88.9575 67.0503H74.7993C74.7703 67.1141 74.7375 67.1779 74.7009 67.2416L68.6289 77.4916H86.8139L88.9575 67.0503Z"
        fill="var(--primary)"/>
      <path d="M70.4154 67.0503H56.3672L46.3398 77.4916H64.2316L70.4154 67.0503Z" fill="var(--primary)"/>
      <path
        d="M63.2509 91.3554V81.3354H43.75V91.3554C43.75 96.8654 48.124 101.348 53.4995 101.348C58.8768 101.348 63.2509 96.8654 63.2509 91.3554Z"
        fill="var(--primary)"/>
      <path
        d="M66.9922 91.3554C66.9922 96.8654 71.3662 101.348 76.7436 101.348C82.1209 101.348 86.495 96.8654 86.495 91.3554V81.3354H66.9922V91.3554Z"
        fill="var(--primary)"/>
      <path
        d="M90.2578 91.3554C90.2578 96.8654 94.6319 101.348 100.009 101.348C105.387 101.348 109.761 96.8654 109.761 91.3554V81.3354H90.2578V91.3554Z"
        fill="var(--primary)"/>
      <path
        d="M123.251 101.348C128.629 101.348 133.003 96.8654 133.003 91.3554V81.3354H113.5V91.3554C113.5 96.8654 117.874 101.348 123.251 101.348Z"
        fill="var(--primary)"/>
      <path
        d="M136.742 81.3354V91.3554C136.742 96.8654 141.116 101.348 146.494 101.348C151.871 101.348 156.243 96.8654 156.243 91.3554V81.3354H136.742Z"
        fill="var(--primary)"/>
    </svg>
  );
}
