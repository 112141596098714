import React from 'react';
import PropTypes from 'prop-types';
import styles from './Login.module.css';

export function DrawerContainer({
  title,
  onClose,
  children
}) {
  return (
    <div className={styles.relative}>
      <div className={styles.verification}>
        <div className={styles.close}>
          <img
            src="/images/feed/back.svg"
            alt=""
            onClick={onClose}
          />
        </div>
        <div className={styles.verificationText}>
          {title}
        </div>
        <div/>
      </div>
      {children}
    </div>
  );
}

DrawerContainer.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

DrawerContainer.defaultProps = {};
