import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Login.module.css';
import cx from 'classnames';
import OtpInput from 'react-otp-input';
import { Button, Clickable } from 'phoenix-components';
import { useCountDown } from 'hooks/timer';
import { DrawerContainer } from 'components/login/DrawerContainer';
import { useRef } from 'react';
import { useEffect } from 'react';

export function OtpComponent({onSubmit, digits, onResend, onClose, title}) {
  const [seconds, restart] = useCountDown(59);
  const [otp, setOtp] = useState('');
  const ref = useRef();

  const onResendClick = async ()=> {
    await onResend();
    restart();
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.querySelector('input').addEventListener('paste', (e) => {
        e.preventDefault();
        e.stopPropagation();
        let value = (e.clipboardData || window.clipboardData).getData('text');
        value = value.trim().replace(/\D/g, '');
        if (/^[0-9]{6}$/.test(value)) {
          setOtp(value);
        }
      }, {
        once: true
      });
    }
  }, [ref])

  return (
    <DrawerContainer title={"Verification"} onClose={onClose}>
      <div className={cx(styles.sub)}>
        {title}
      </div>
      <div ref={ref}>
        <OtpInput
          value={otp}
          onChange={(e) => setOtp(e)}
          numInputs={digits}
          inputStyle={styles.input}
          focusStyle={styles.focus}
          containerStyle={styles.conStyle}
          isInputNum={true}
        />
      </div>
      {otp.length > 0 && otp.length < digits && (
        <div className={styles.err}>
          OTP should be {digits} digit code
        </div>
      )}
      <div className={styles.timer}>
        00:
        {seconds.toString()
          .padStart(2, '0')}
      </div>
      {seconds <= 0 && (
        <div className={cx(styles.resendCon)}>
          Didn’t receive a code?
          <Clickable
            className={styles.resend}
            onClick={onResendClick}
          >
            Resend
          </Clickable>
        </div>
      )}
      <div className={styles.center}>
        <Button
          className={styles.submitButton}
          onClick={() => onSubmit(otp)}
          label="Verify"
          size="large"
          fullWidth
          rounded={false}
        />
      </div>
    </DrawerContainer>
  );
}

OtpComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  digits: PropTypes.number.isRequired,
  onResend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

OtpComponent.defaultProps = {};
